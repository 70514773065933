import styled from 'styled-components';

export const StyledHeader = styled.h1`
  color: ${({ theme }) => theme.color.text.primary};
  font-size: 40px;
  line-height: 76px;
  font-weight: 700;
  margin: 50px 0 0 0;
  text-transform: uppercase;
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    font-size: 45px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    font-size: 63px;
  }
`;

export const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.color.text.secondary};
  font-size: 18px;
  line-height: 32px;
  margin: 0 0 10px 0;
  align-self: flex-start;
  a:link {
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.background.transparent};
    text-decoration: none;
  }
  a:hover {
    opacity: 0.6;
  }
  a:visited {
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.background.transparent};
    text-decoration: none;
  }
`;

export const StyledIntro = styled(StyledParagraph as any)`
  font-weight: bold;
`;

export const Separator = styled.div`
  margin-bottom: 100px;
`;
