import Footer from 'components/CommonComponents/Footer';
import NavigationBar from 'components/CommonComponents/NavigationBar';
import SEO from 'components/CommonComponents/SEO';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { Separator, StyledHeader, StyledIntro, StyledParagraph } from 'components/PageCareer/Gdpr/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';

const pageQuery = graphql`
  {
    gdprYaml {
      header {
        cs
      }
      paragraphs {
        cs
      }
    }
  }
`;

const Gdpr = () => {
  const locale = 'cs';
  const data = useStaticQuery(pageQuery);

  const gdprData = data.gdprYaml;

  return (
    <>
      <SEO title="GDPR" />
      <NavigationBar />
      <main>
        <StyledSection>
          <StyledHeader>{gdprData.header[locale]}</StyledHeader>
          <Separator />
          {gdprData.paragraphs[locale].map((text: string, index: number) => (
            <Fragment key={index}>
              {index === 0 ? <StyledIntro>{text}</StyledIntro> : <StyledParagraph>{ReactHtmlParser(text)}</StyledParagraph>}
            </Fragment>
          ))}
        </StyledSection>
        <Separator />
      </main>
      <Footer />
    </>
  );
};

export default Gdpr;
